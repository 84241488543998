import Vue from 'vue'
import VueRouter from 'vue-router'
import Writing from '../views/Writing'
import Close from '../views/Close'
import Reward from '../views/Reward'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'writing',
    component: Writing
  },

  {
    path: '/daily_close',
    name: 'daily_close',
    component: Close
  },

  {
    path: '/reward_close',
    name: 'reward_close',
    component: Reward
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
