<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "Close",

  mounted() {

  },

  methods: {

  },
}
</script>

<style scoped>

</style>
