import Vue from 'vue'
import App from './App.vue'
import store from './store';

import './plugins/axios'

import axios from 'axios';
import router from './router'
import vueDragula from 'vue-dragula'

Vue.prototype.$Axios = axios;
Vue.config.productionTip = false
Vue.use(vueDragula)

new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    dragula: Vue.vueDragula
  },
}).$mount('#app')
