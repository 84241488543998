<template>
  <div class="writing" id="vue_app">
    <div v-show="is_loader">
      <div
          style="background: #dd365e; position: fixed; background-size: cover; width: 100%; height: 100%; z-index:-1;"></div>
      <div style="margin: 0 auto; text-align: center; padding-top: 150px;"><img
          src="../assets/writing/imgs/error.png" alt="err_img"></div>
    </div>
    <div v-show="!is_loader"><!--<div v-show="!is_loader" style="display:none;">-->
      <div class="que_type1" v-if="que_type===1">
        <!-- <a class="location-close" href="#" @click="btn_close()">
          <img alt="" src="../assets/writing/imgs/arrow_left.png">
        </a> -->
        <!-- <div class="carrot_header">
          <div class="info" v-on:click="btn_popinfo_show()">
            <img src="../assets/writing/imgs/ico_info.png"></div>
          <h1>오늘의 영작</h1>
          <span>오늘의 주요 표현을 활용하여 영작해 보세요.</span>
        </div> -->


        <div div class="platformHeader">
          <a class="location-close" href="#" @click="btn_close()">
            <img src="../assets/writing/imgs/arrow_left.png"/>
          </a>

          <p>Daily Writing</p>
          <span v-on:click="btn_popinfo_show()"><img
              src="../assets/writing/imgs/ico_info.png"/></span>
        </div>
        <div class="box">
          <div class="daily-top">
            <p><span>오늘의영작</span><br>오늘의 주요 표현을 활용하여 영작해 보세요.</p>
          </div>
          <div class="que">
            {{ content.qtp1.que }}
          </div>
          <textarea v-on:focus="hideFooter()" v-on:blur="showFooter()" v-model='content.qtp1.answer' class="ans" placeholder="your text here" @keyup.enter='enterSubmit(que_type)'></textarea>

          <div class="wordbox" v-if="!que_type1_reply">
            <div class="items" v-for="itm in content.qtp1.items" :key="itm.index">
              <div class="itm_l" v-on:click="playAudio(itm.txt, itm.audio)"><img
                  src="../assets/writing/imgs/daily_recoard.png" alt="Audio Play">
              </div>
              <div class="itm_r" v-on:click="playAudio(itm.txt, itm.audio)">
                <b>{{ itm.txt }}</b>
                {{ itm.ktxt }}
              </div>
              <div class="itm_line"></div>
            </div>
          </div>

          <div v-if="que_type1_reply" class="rans">
            <h2>정답</h2>
            <h4 v-html="content.qtp1.reply.html"></h4>
          </div>
          <div class="box_btn">
            <button class="btn" v-on:click="q1_btn_sound()">힌트듣기</button> &nbsp;
            <button class="btn" v-on:click="q1_btn_show_reply()" v-if="que_type1_reply">돌아가기</button>
            <button class="btn" v-on:click="q1_btn_show_reply()" v-else>정답확인</button>
          </div>

          <div class="box" style="padding: 10px 20px;">
            <div class="review-click" v-if="code==427"
                v-on:click="location.href='http://new.carrotenglish.com/cafe/pop_news_learn_recommend_daily.php'">
              · <span>Reuter News</span>로 좀 더 공부해볼까요? <span>Click!</span>
            </div>
            <div class="review-click" style="margin-top: 5px;" v-if="code==427"
                v-on:click="location.href='/review/'+idx+'/'+code">
              · 지난 학습 <span>복습</span>하러 가기 <span>Click!</span>
            </div>
          </div>
        </div>

        <!-- <div class="box_navi">
          <div class="daily-btn">
            <div v-on:click="btn_next()">NEXT <img src="../assets/writing/imgs/btn_next.png"></div>
          </div>
        </div> -->

      </div>
      <div class="que_type2" v-if="que_type === 2">
        <!-- <a class="location-close" href="#" @click="btn_close()">
          <img alt="" src="../assets/writing/imgs/arrow_left.png">
        </a>
        <div class="carrot_header">
          <div class="info" style="padding-top: 25px; padding-right: 10px;"
               v-on:click="btn_popinfo_show()"><img src="../assets/writing/imgs/ico_info.png">
          </div>
          <h1>문장 구성하기</h1>
          <span>다음 문장을 어순에 맞게 드래그 해 보세요.</span>
        </div> -->
        <div div class="platformHeader">
          <a class="location-close" href="#" @click="btn_close()">
            <img src="../assets/writing/imgs/arrow_left.png"/>
          </a>
          <p>Daily Writing</p>
          <span v-on:click="btn_popinfo_show()"><img
              src="../assets/writing/imgs/ico_info.png"/></span>
        </div>
        <div class="box">
          <div class="daily-top">
            <p><span>문장 구성하기</span><br>다음 문장을 어순에 맞게 드래그 해 보세요.</p>
          </div>
          <div class="que_drag">
            {{ content.qtp2Text }}
            <span v-for="(itm, i) in content.qtp2Items" v-dragula='"ans-bag"' drake='items'
                  class="que_box" :key="i" v-bind:class="'pos'+ (i+1)">
            </span>
          </div>
          <div v-for="(itm, i) in content.qtp2Items" class="ans_box" v-dragula='"ans-bag"'
               :key="i" drake='items'>
            <div class="ans_itm" v-bind:class="itm.pos">{{ itm.txt }}</div>
          </div>
        </div>
        <div class="box" style="padding: 10px 20px;">
          <div class="review-click" v-if="code==427"
               v-on:click="location.href='http://new.carrotenglish.com/cafe/pop_news_learn_recommend_daily.php'">
            · <span>Reuter News</span>로 좀 더 공부해볼까요? <span>Click!</span>
          </div>
          <div class="review-click" style="margin-top: 5px;" v-if="code==427"
               v-on:click="location.href='/review/'+idx+'/'+code">
            · 지난 학습 <span>복습</span>하러 가기 <span>Click!</span>
          </div>
        </div>
        <!-- <div class="box_navi">
          <div class="daily-btn">
            <div v-on:click="btn_prev()"><img src="../assets/writing/imgs/btn_prev.png"> PREV</div>
            <div v-on:click="btn_next()">NEXT <img src="../assets/writing/imgs/btn_next.png"></div>
          </div>
        </div> -->
      </div>
      <div class="que_type3" v-if="que_type === 3">
        <!-- <a class="location-close" href="#" @click="btn_close()">
          <img alt="" src="../assets/writing/imgs/arrow_left.png">
        </a>
        <div class="carrot_header">
          <div class="info" style="padding-top: 25px; padding-right: 10px;"
               v-on:click="btn_popinfo_show()"><img src="../assets/writing/imgs/ico_info.png">
          </div>
          <h1>활용영역 확장하기</h1>
          <span>다양한 단어를 활용하여 확장 학습을 해 보세요.</span>
        </div> -->
        <div div class="platformHeader">
          <a class="location-close" href="#" @click="btn_close()">
            <img src="../assets/writing/imgs/arrow_left.png"/>
          </a>
          <p>Daily Writing</p>
          <span v-on:click="btn_popinfo_show()"><img
              src="../assets/writing/imgs/ico_info.png"/></span>
        </div>
        <div class="box">
          <div class="daily-top">
            <p><span>활용영역 확장하기</span><br>다양한 단어를 활용하여 확장 학습을 해 보세요.</p>
          </div>
          <div class="que" v-html="content.qtp3.render_eng">
          </div>
          <div class="que_kor" v-html="content.qtp3.render_kor">
          </div>
          <div class="wordbox">
            <div v-for="itm in content.qtp3.itm" v-on:click="q3_btn_select(itm)" :key="itm.index">

              <span v-on:click="q3_btn_select_mp3(itm)">
                <img src="../assets/writing/imgs/daily_recoard.png" alt="Audio Play">&nbsp;&nbsp;{{ itm.txt }}</span>

              <!-- <div class="ico_mp4" v-on:click="q3_btn_select_mp3(itm)"></div> -->
            </div>
          </div>
        </div>
        <div class="box" style="padding: 10px 20px;">
          <div class="review-click" v-if="code==427"
               v-on:click="location.href='http://new.carrotenglish.com/cafe/pop_news_learn_recommend_daily.php'">
            · <span>Reuter News</span>로 좀 더 공부해볼까요? <span>Click!</span>
          </div>
          <div class="review-click" style="margin-top: 5px;" v-if="code==427"
               v-on:click="location.href='/review/'+idx+'/'+code">
            · 지난 학습 <span>복습</span>하러 가기 <span>Click!</span>
          </div>
        </div>
        <!-- <div class="box_navi">
          <div class="daily-btn">
            <div v-on:click="btn_prev()"><img src="../assets/writing/imgs/btn_prev.png"> PREV</div>
            <div v-on:click="btn_next()">NEXT <img src="../assets/writing/imgs/btn_next.png"></div>
          </div>
        </div> -->
      </div>
      <div class="que_type4" v-if="que_type==4">
        <!-- <div class="carrot_header">
          <div class="info" style="padding-top: 25px; padding-right: 10px;"
               v-on:click="btn_popinfo_show()"><img src="../assets/writing/imgs/ico_info.png">
          </div>
          <h1>알맞은 어휘 쓰기</h1>
          <span>빈칸의 알맞은 단어를 타이핑 해 보세요.</span>
        </div> -->
        <div div class="platformHeader">
          <a class="location-close" href="#" @click="btn_close()">
            <img src="../assets/writing/imgs/arrow_left.png"/>
          </a>
          <p>Daily Writing</p>
          <span v-on:click="btn_popinfo_show()"><img
              src="../assets/writing/imgs/ico_info.png"/></span>
        </div>
        <div class="box">
          <div class="daily-top">
            <p><span>알맞은 어휘 쓰기</span><br>빈칸의 알맞은 단어를 타이핑 해 보세요.</p>
          </div>
          <div class="que">
            {{ content.qtp4.que_eng_prev }} <span>{{ content.qtp4.answer }}</span>
            {{ content.qtp4.que_eng_next }}
          </div>
          <div class="que_kor" v-html="content.qtp4.que_kor">
          </div>
          <input v-on:focus="hideFooter()" v-on:blur="showFooter()" class="qtp4_answer"
                 type="text"
                 v-model='content.qtp4.answer' placeholder="your text here" @keyup.enter='enterSubmit(que_type)'>
          <div class="box_btn">
            <button class="btn" v-on:click="q4_btn_check()">정답확인</button>
          </div>
        </div>
        <div class="box" style="padding: 10px 20px;">
          <div class="review-click" v-if="code==427"
               v-on:click="location.href='http://new.carrotenglish.com/cafe/pop_news_learn_recommend_daily.php'">
            · <span>Reuter News</span>로 좀 더 공부해볼까요? <span>Click!</span>
          </div>
          <div class="review-click" style="margin-top: 5px;" v-if="code==427"
               v-on:click="location.href='/review/'+idx+'/'+code">
            · 지난 학습 <span>복습</span>하러 가기 <span>Click!</span>
          </div>
        </div>
        <!-- <div class="box_navi">
          <div class="daily-btn">
            <div v-on:click="btn_prev()"><img src="../assets/writing/imgs/btn_prev.png">  PREV</div>
            <div v-on:click="btn_next()">NEXT <img src="../assets/writing/imgs/btn_next.png"></div>
          </div>
        </div> -->
      </div>
      <div class="que_type5" v-if="que_type==5">
        <!-- <div class="carrot_header">
          <div class="info" style="padding-top: 25px; padding-right: 10px;"
               v-on:click="btn_popinfo_show()"><img src="../assets/writing/imgs/ico_info.png">
          </div>
          <h1>오늘의 영작 완전 마무리!</h1>
          <span>오늘 학습한 내용을 완벽하게 마스터 해 보세요.</span>
        </div> -->
        <div div class="platformHeader">
          <a class="location-close" href="#" @click="btn_close()">
            <img src="../assets/writing/imgs/arrow_left.png"/>
          </a>
          <p>Daily Writing</p>
          <span v-on:click="btn_popinfo_show()"><img
              src="../assets/writing/imgs/ico_info.png"/></span>
        </div>
        <div class="box">
          <div class="daily-top">
            <p><span>오늘의 영작 완전 마무리!</span><br>오늘 학습한 내용을 완벽하게 마스터 해 보세요.</p>
          </div>
          <div class="que">
            {{ content.qtp5.que }}
          </div>
          <textarea v-on:focus="hideFooter()" v-on:blur="showFooter()" v-model='content.qtp5.answer'
                    class="ans" placeholder="your text here"></textarea>

          <div v-if="que_type1_reply" class="rans">
            <h2>정답</h2>
            <h4 v-html="content.qtp5.reply"></h4>
          </div>
          <div class="box_btn">
            <button class="btn" v-on:click="q1_btn_show_reply()">정답확인</button>
          </div>
        </div>
        <div class="box" style="padding: 10px 20px;">
          <div class="review-click" v-if="code==427"
               v-on:click="location.href='http://new.carrotenglish.com/cafe/pop_news_learn_recommend_daily.php'">
            · <span>Reuter News</span>로 좀 더 공부해볼까요? <span>Click!</span>
          </div>
          <div class="review-click" style="margin-top: 5px;" v-if="code==427"
               v-on:click="location.href='/review/'+idx+'/'+code">
            · 지난 학습 <span>복습</span>하러 가기 <span>Click!</span>
          </div>
        </div>
        <!-- <div class="box_navi">
          <div class="daily-btn">
            <div v-on:click="btn_prev()"><img src="../assets/writing/imgs/btn_prev.png">  PREV</div>
            <div v-on:click="btn_next()">NEXT <img src="../assets/writing/imgs/btn_next.png"></div>
          </div>
        </div> -->
      </div>
      <div class="que_type6" v-if="que_type===6">
        <div v-show="is_reward_user === true">
          <!-- <div class="carrot_header">
            <div class="info" style="padding-top: 25px; padding-right: 10px;"
                 v-on:click="btn_popinfo_show()"><img src="../assets/writing/imgs/ico_info.png">
            </div>
            <h1>학습 완료</h1>
            <span><b>60일</b>을 채우면 <b>스타벅스 커피</b>가 내손에!</span>
          </div> -->
          <div div class="platformHeader">
            <a class="location-close" href="#" @click="btn_close()">
              <img src="../assets/writing/imgs/arrow_left.png"/>
            </a>
            <p>Daily Writing</p>
            <span v-on:click="btn_popinfo_show()"><img
                src="../assets/writing/imgs/ico_info.png"/></span>
          </div>
          <div class="box">
            <div class="daily-top">
              <img src="../assets/writing/imgs/daily_end.png" style="padding-top: 40px;">
              <p><span>학습이 모두 끝났습니다!</span><br>오늘 학습한 문구로<br>스피킹 훈련도 함께 해 볼까요?</p>
            </div>

            <div class="btn-wrap">
              <button class="btn-orange" v-on:click="btn_say()">스피킹 훈련 하기</button>
              <!-- <div class="daily-btn">
                <div >PREV</div>
                <div >NEXT</div>
              </div> -->
            </div>

            <!-- <div class="box_stamp_header"><b>{{attend}}</b>/60</div>
            <div class="box_stamp">
              <div class="box_stamp_one" v-for="itm in stemps" :key="itm.index">
                <div v-if="itm.text===''" v-bind:class="{star:itm.text===''}"><img src="../assets/writing/imgs/star.png"></div>
                <div class="stamp" v-bind:class="{stamp_disable:itm.progress<5}">
                  <div class="stamp_txt" v-bind:class="{icon:itm.text==='', chk:itm.progress===5, txt_disable:itm.progress===0}"><span v-html="itm.text" v-if="itm.progress<5"></span>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress_1" v-bind:class="{progress_ok:itm.progress<1}"></div>
                  <div class="progress_2" v-bind:class="{progress_ok:itm.progress<2}"></div>
                  <div class="progress_3" v-bind:class="{progress_ok:itm.progress<3}"></div>
                  <div class="progress_4" v-bind:class="{progress_ok:itm.progress<4}"></div>
                  <div class="progress_5" v-bind:class="{progress_ok:itm.progress<5}"></div>
                </div>
              </div>
              <div class="cb"></div>
            </div>
            <div class="blank"></div> -->
            <!--
            <div class="unsubscribe_lnk" v-on:click="unsubscribe()">수신거부설정</div>
            -->
          </div>

          <div style="color:red; text-align:right;margin-right: 20px;"
               v-if="code===427"
               v-on:click="location.href='http://new.carrotenglish.com/cafe/pop_news_learn_recommend_daily.php'">
            * Reuter News로 좀 더 공부해볼까요. 클릭!
          </div>
        </div>

        <!-- <div v-show="is_reward_user === false">
          <div class="carrot_header">
            <div class="info" style="padding-top: 25px; padding-right: 10px;"
                 v-on:click="btn_popinfo_show()"><img src="../assets/writing/imgs/ico_info.png">
            </div>
            <h1>학습 완료</h1>
            <span>오늘 학습이 마감되었습니다.</span>
          </div>
          <div>
            <div
                style="position: fixed; background-size: cover; width: 100%; height: 100%; z-index:-1;"></div>
            <div style="margin: 0 auto; text-align: center; padding-top: 150px;">
              <img src="../assets/writing/imgs/end.png"></div>
          </div>
          <div class="box" style="padding: 10px 20px;">
            <div class="review-click" v-if="code==427"
                 v-on:click="location.href='http://new.carrotenglish.com/cafe/pop_news_learn_recommend_daily.php'">
              · <span>Reuter News</span>로 좀 더 공부해볼까요? <span>Click!</span>
            </div>
            <div class="review-click" style="margin-top: 5px;" v-if="code==427"
                 v-on:click="location.href='/review/'+idx+'/'+code">
              · 지난 학습 <span>복습</span>하러 가기 <span>Click!</span>
            </div>
          </div>
        </div> -->

      </div>
      <div class="popX" v-bind:class="{popHide:popResult_hide === 1}" v-if="popResult === 2"></div>
      <div class="popO" v-bind:class="{popHide:popResult_hide === 1}" v-if="popResult === 1"></div>
      <div class="popInfo" v-if="popinfo">
        <img src="../assets/writing/imgs/pop.png" style="width: 100%; max-width: 354px;"
             v-on:click="btn_popinfo_close()">
      </div>
      <div id="tts"></div>
      <!-- <div class="footer" v-if="footer"> -->
      <!-- <div v-if="!footer2">
        <div class="fl">
          <div class="btn_pre" v-if="que_type!=1" v-bind:class="{btn_disable:que_type==1}"
               v-on:click="btn_prev()">Prev
          </div>
        </div>
        <div class="fr">
          <div class="btn_next" v-if="que_type!=6"
               v-bind:class="{btn_disable:que_type==6}"
               v-on:click="btn_next()">Next
          </div>
        </div>
        <div class="cb"></div>
      </div> -->
      <!-- <div class="cb"></div> -->
      <!-- <div v-if="code=='427'" class="footerinfo">Copyright ⓒ <b>GS E&C Corp</b>. All Right
        reserved.
      </div>
      <div v-else class="footerinfo">Copyright ⓒ <b>CARROT</b>Global Inc. All Right reserved.
      </div> -->


      <!-- </div> -->

      <div class="bottomDiv">
        <div class="box_navi_new" v-if="que_type != 6">
          <div class="daily-btn">
            <div v-on:click="btn_prev()" v-if="que_type != 1"><img src="../assets/writing/imgs/btn_prev.png"> PREV</div>
            <div v-on:click="btn_next()">NEXT <img src="../assets/writing/imgs/btn_next.png"></div>
          </div>
        </div>
        <div class="mainFooter_new">
          <p>powered by <strong>CARROT</strong> all right reserved.</p>
        </div>
      </div>
      <!-- <div class="mainFooter" v-if="footer"> -->
        <!-- <p>powered by <strong>CARROT</strong> all right reserved.</p> -->
        <!-- <div>
            <span @click="open_footer()">(주)캐럿솔루션즈 사업자 정보 -->
              <!-- <ion-icon name="caret-down-outline"  v-if="!is_footer_detail"></ion-icon>
              <ion-icon name="caret-up-outline" v-if="is_footer_detail"></ion-icon> -->
              <!-- <i class="fas fa-chevron-down" v-if="!is_footer_detail"></i>
              <i class="fas fa-chevron-up" v-if="is_footer_detail"></i>
            </span>
          <p v-if="is_footer_detail">(주)캐럿솔루션즈|서울특별시 강남구 강남대로 488 대남빌딩 4, 6, 8, 9층 06120
            <br>사업자 등록번호 211-88-50791 | 통신판매업 신고번호제 2017-서울강남-02979 호
          </p>
          <p><span>이용약관</span> | <span>개인정보처리방침</span></p>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "writing",

  data() {
    return {
      content: {
        qtp1: {
          que: "그는 새로운 모바일 디바이스를 담당하고 있는 프로덕트 매니저이다.",
          reply: {
            text: "He is a product manager in charge in launching new mobile devices.",
            html: "He is a <b>product manager in charge of</b> launching new mobile devices."
          },
          items: [{
            txt: "product manager",
            ktxt: "생산매니저, 생산과장",
            audio: ""
          }, {
            txt: "in charge of",
            ktxt: "~을 담당하는, 책임지는",
            audio: ""
          }],
          audio: "../assets/sound/1.mp3",
          answer: ""
        },
        qtp2Text: "He is a ",
        qtp2Items: [{
          txt: "New mobile devices",
          pos: "pos4"
        }, {
          txt: "in charge of",
          pos: "pos2"
        }, {
          txt: "Launching",
          pos: "pos3"
        }, {
          txt: "Product manager",
          pos: "pos1"
        }],
        qtp3: {
          render_eng: "He is a <b>product manager</b> in charge of launching new mobile devices.",
          render_kor: "그는 새로운 모바일 디바이스를 담당하고 있는 <b>프로덕트 매니저</b>이다.",
          eng: "He is a [] in charge of launching new mobile devices.",
          kor: "그는 새로운 모바일 디바이스를 담당하고 있는 []이다.",
          itm: [{
            txt: "product manager",
            ktxt: "프로덕트 매니저",
            audio: ""
          }, {
            txt: "assistant manager ",
            ktxt: "부 수석 매니저",
            audio: ""
          }, {
            txt: "general manager ",
            ktxt: "총괄 매니저",
            audio: ""
          }]
        },
        qtp4: {
          que_eng: "He is a product manager [] launching new mobile devices.",
          que_eng_prev: "He is a product manager ",
          que_eng_next: " launching new mobile devices.",
          que_kor: "그는 새로운 모바일 디바이스를 담당하고 있는 프로덕트 매니저이다.",
          reply: "in charge of",
          answer: ""
        },
        qtp5: {
          que: "그는 새로운 모바일 디바이스를 담당하고 있는 제품 매니저 매니저이다.",
          reply: "He is a product manager in charge of launching new mobile devices.",
          answer: ""
        }
      },
      code: 1,
      dw_content: {},
      attend: 0,
      popResult: 0,
      popResult_hide: 0,
      que_type: 1,
      popinfo: false,
      que_type1_reply: false,
      footer: true,
      footer2: false,
      rw_chk: false,
      is_loader: true,
      is_reward_user: false,
      is_footer_detail: false,
      stemps: [{
        progress: 0,
        text: "테스트"
      }, {
        progress: 0,
        text: "영작실력<br>쑥쑥!!"
      }, {
        progress: 0,
        text: "포기하지<br>말아요!"
      }, {
        progress: 0,
        text: "벌써<br>한달 째!"
      }, {
        progress: 0,
        text: "비결은<br>꾸준함!"
      }, {
        progress: 0,
        text: "이만큼이나<br>왔어요!"
      }, {
        progress: 0,
        text: "대단해요!"
      }, {
        progress: 0,
        text: "와우!<br>두달 째!"
      }, {
        progress: 0,
        text: "최고를<br>향해!"
      }, {
        progress: 0,
        text: "조금만 더<br>힘내요!"
      }, {
        progress: 0,
        text: "고지가<br>눈앞!"
      }, {
        progress: 0,
        text: ""
      }],
      newMySound:null,
      isTtsPlay :0
    }
  },

  mounted() {
    // document.dispatchEvent(new CustomEvent("sendEvent", {
    //   detail: { category: "보라" ,action:'test',label:'test1'}
    // }));

    /// 캐시 삭제
    caches
    .keys()
    .then(c => {
      for (const i of c) {
        caches.delete(i);
      }
    })

    this.is_loader = false;
    this.init();
    this.$root.dragula.eventBus.$on('drop', (value) => {

      /*
      각 Value 정보
      value[0] = globalBag =>
      value[1] = div.ans_itm =>
      value[2] = span.que_box.postxt1 =>
      value[3] = div.ans_box =>
      */

      if (value[2].classList[0] === "que_box") {
        if (value[1].classList[1] === value[2].classList[1]) {
          value[2].outerHTML = value[1].innerHTML + ' ';
          value[2].style.display = "inline";
          value[2].style.border = "0";
          // this.playO();
        } else {
          this.$root.dragula.find('globalBag').drake.cancel(true);
          // this.playX();
        }
      }
    });
  },

  methods: {
    enterSubmit:function(que_type){
      // alert('a');
      // if(que_type == 1){
      //   this.q1_btn_show_reply();
      // }else if(que_type == 4){
      //   this.q4_btn_check();
      // }
      if(que_type == 4){
        this.q4_btn_check();
      }
    },
    open_footer: function () {
      if (this.is_footer_detail) {
        this.is_footer_detail = false;
      } else {
        this.is_footer_detail = true;
      }
    },
    /// 데일리 라이팅 시작
    init: function () {
      /// 데일리 라이팅 회원인지 확인

      if (this.$route.query.idx === undefined) {
        this.is_loader = true;
        return;
      }

      const url = this.$store.state.host + "/dw/today?idx=" + this.$route.query.idx;
      this.axios.get(url)
      .then((res) => {
        /// 드래그 아이템 랜덤으로 섞기
        let itemArr = res.data.result.qtp2Items;
        let shuffled = itemArr
        .map(a => ([Math.random(), a]))
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1])

        this.content = res.data.result;
        this.content.qtp2Items = shuffled;
        if (res.data.token !== undefined) {
          this.token = res.data.token;
          this.is_reward_user = true;
        }
      })
    },
    /// 스타벅스 리워드 처리
    out_last: function () {
      const url = this.$store.state.host + "/dw/complete";
      const params = {
        idx: Number(this.$route.query.idx),
        token: ((this.token !== undefined) ? this.token : null)
      }
      this.axios.post(url, params).then((res) => {
        /// 바뀐 리워드 화면 처리
        if (res.data.status === 0) {
          this.stemps = res.data.result.stemps;
          this.attend = res.data.result.attend;


          let level = res.data.result.level;
          document.dispatchEvent(new CustomEvent("sendEvent", {
            detail: { category: "DailyWriting" ,action:'click',label:'Daily Writing Complete'}
          }));
          if(level == 'A'){
            document.dispatchEvent(new CustomEvent("sendEvent", {
              detail: { category: "DailyWriting" ,action:'click',label:'Daily Writing Middle Complete'}
            }));
          }else{
            document.dispatchEvent(new CustomEvent("sendEvent", {
              detail: { category: "DailyWriting" ,action:'click',label:'Daily Writing Begin Complete'}
            }));
          }
        }
        this.location_reward();
      });

    },
    /// 닫기버튼
    btn_close: function () {
      location.href = "http://carrot.com";
    },
    /// 리워드 화면 이동
    location_reward: function () {
      // setTimeout(() => {
      //   location.href = "http://reward.carrot.com/"
      // }, 2000)
    },
    /// 팝업화면 띄우기
    btn_popinfo_close: function () {
      this.popinfo = false;
    },
    btn_popinfo_show: function () {
      this.popinfo = true;
    },
    isMobile: function () {
      var mobileKeyWords =
          ['iPhone', 'iPod', 'BlackBerry', 'Android', 'Windows CE', 'LG', 'MOT', 'SAMSUNG',
            'SonyEricsson', 'Chrome', 'safari', 'Safari'];
      var chkBrowser = 'web';
      for (var word in mobileKeyWords) {
        if (navigator.userAgent.match(mobileKeyWords[word]) != null) {
          chkBrowser = 'mobile'
        }
      }
      return chkBrowser;
    },
    playO: function () {
      this.playMp3('/mp3/correct.mp3');
    },
    playX: function () {
      this.playMp3('/mp3/incorrect.mp3');
    },
    playAudio: function (txt, url) {
      if (url === "") {
        // console.log()
        if(this.isTtsPlay == 0){
          this.playTTS(txt);
        }
      } else {
        this.playMp3(url);
      }
    },
    playMp3: function (url) {
      let chkBrowser = this.isMobile();
      let ttsElement = document.querySelector('#tts');

      /// 재생 엘리먼트 분기
      if (chkBrowser === 'web') {
        ttsElement.innerHTML
            = '<embed id="ttsAudio"  height="60" src="' + url + '" style="display:none"/>';
      } else {
        ttsElement.innerHTML
            = '<audio id="ttsAudio" style="display:none">' +
            '<source src="' + url + '" type="audio/mpeg"/></audio>';
      }
      let audioElement = document.querySelector('#ttsAudio');
      audioElement.cloneNode(true).play();
    },
    playTTS: function (word) {
      let me = this;
      this.isTtsPlay = 1;
      word = word.replace(/(<([^>]+)>)/ig, "");
      var params = {
        text: word,
        voice: "A" // 목소리 유형 A, B, C, en-US-Wavenet-A
      }
      console.log(params);
      
      // console.log("is playing " + this.newMySound.paused);
      this.axios.get("https://platform-dw.carrotenglish.net/tts", {params: params})
      .then(function (res) {
        const mySound = new Audio(res.data);
        mySound.play();
        mySound.onended = (event => {
          console.log(event);
          console.log("end");
          me.isTtsPlay = 0;
        });
      });
      // playtts(word);
    },
    q1_btn_show_reply: function () {
      this.que_type1_reply = (this.que_type1_reply !== true);
      console.log(this.que_type1_reply);
    },
    q1_btn_sound: function () {
      this.playTTS(this.content.qtp1.reply.txt);
      /*if (this.content.qtp1.audio === "") {
      } else {
        this.playMp3(this.content.qtp1.audio);
      }*/
    },
    q3_btn_select_mp3: function (itm) {
      this.content.qtp3.render_eng = this.content.qtp3.eng.replace("[]", "<b>" + itm.txt + "</b>");
      this.content.qtp3.render_kor = this.content.qtp3.kor.replace("[]", "<b>" + itm.ktxt + "</b>");
      this.playAudio(this.content.qtp3.render_eng, itm.audio)
    },
    q3_btn_select: function (itm) {
      this.content.qtp3.render_eng = this.content.qtp3.eng.replace("[]", "<b>" + itm.txt + "</b>");
      this.content.qtp3.render_kor = this.content.qtp3.kor.replace("[]", "<b>" + itm.ktxt + "</b>");
    },
    q4_btn_check: function () {
      var tmp = this;
      //var reply = this.content.qtp4.reply.toLowerCase();
      var answer = this.content.qtp4.answer.toLowerCase();
      var answer1 = this.content.qtp3.itm[0].txt.toLowerCase();
      var answer2 = this.content.qtp3.itm[1].txt.toLowerCase();
      var answer3 = this.content.qtp3.itm[2].txt.toLowerCase();

      if (answer.trim() === answer1.trim()
          || answer.trim() === answer2.trim()
          || answer.trim() === answer3.trim()
      ) {
        this.popResult = 1;
        this.popResult_hide = 0;
        // this.playO();
      } else {
        this.popResult = 2;
        this.popResult_hide = 0;
        // this.playX();
      }
      setTimeout(function () {
        tmp.popResult_hide = 1;
      }, 500);
    },
    hideFooter: function () {
      this.footer = false;
    },
    showFooter: function () {
      this.footer = true;
    },
    /// 버튼클릭
    btn_next: function () {
      /// 페이지 N인데 오답인 경우들
      console.log(this.que_type, this.content.qtp1.answer);
      if (this.que_type === 1 && (this.content.qtp1.answer === "" || this.content.qtp1.answer
          === undefined)) {
        // this.playX();
        this.popResult = 2;
        this.popResult_hide = 0;
        setTimeout(() => {
          this.popResult_hide = 1;
        }, 500);
        return;
      }
      if (this.que_type === 4 && this.content.qtp4.answer === "") {
        // this.playX();
        this.popResult = 2;
        this.popResult_hide = 0;
        setTimeout(() => {
          this.popResult_hide = 1;
        }, 500);
        return;
      }
      if (this.que_type === 5 && this.content.qtp5.answer === "") {
        // this.playX();
        this.popResult = 2;
        this.popResult_hide = 0;
        setTimeout(() => {
          this.popResult_hide = 1;
        }, 500);
        return;
      }

      /// 페이지 넘기기
      if (this.que_type >= 6) {
        this.que_type = 6;
      } else {
        this.que_type1_reply = false;
        this.que_type = this.que_type + 1;
      }

      if (this.que_type === 6) {
        this.out_last();
      }
    },
    btn_prev: function () {
      if (this.que_type <= 1) {
        this.que_type = 1;
      } else {
        this.que_type1_reply = false;
        this.que_type--;
      }
    },
    btn_say: function () {
      location.href = "http://carrotsay.com";
    }
  }
}
</script>

<style scoped>
.location-close {
  cursor: pointer;
  position: absolute;
  z-index: 999;
  float: left;
  left: 10px;
  top: 14px;
  width: 26px;
}

.location-close img {
  width: 50%;
  padding-top: 5px;
}
</style>
